<template>
  <FieldWrapper :field="field">
    <button
      class="btn"
      :class="[colorClass, { 'spinner spinner-white spinner-right': running }]"
      :disabled="(running && !cancelable) || isDisabled"
      @click="onAction"
    >
      {{ buttonLabel }}
    </button>
  </FieldWrapper>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ApiService from "@/core/services/api.service";

export default {
  components: { FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {
      disabled: false,
      running: false,
      ActionApiService: undefined
    };
  },
  computed: {
    // Return if request is running and cancelable
    cancelable: function () {
      return this.running;
    },
    buttonLabel: function () {
      return this.cancelable
        ? this.$t("formHelper.cancel")
        : this.getSnippet(
            this.field.buttonLabel ?? this.field.label ?? this.field.name
          );
    },
    colorClass: function () {
      return "btn-" + (this.field.color ? this.field.color : "primary");
    }
  },
  mounted() {
    // Set default headers for ApiService
    this.ActionApiService = ApiService;
  },
  methods: {
    // Fires on button click
    async onAction() {
      if (this.field.action === undefined) {
        // Fire toast for information
        this.noAction();
      }
      if (this.running && !this.cancelable) {
        // If action is running but not cancelable, return
        return;
      } else if (this.running && this.cancelable) {
        // Fire notification
        this.requestCanceled();
        return;
      }
      // Disable button
      this.running = true;
      if (typeof this.field.action === "function") {
        // If action is a js function, execute it
        await this.field.action();
      } else if (typeof this.field.action === "object") {
        // If action is request config
        await this.fireRequest();
      } else {
        // If action is string, emit action event for custom handling
        this.$emit("action", this.field.action);
      }
      // Enable button
      this.running = false;
    },
    async fireRequest() {
      // Send request by request method
      switch (this.field.action.method) {
        case "GET":
          await this.requestGET();
          break;
        case "POST":
          await this.requestPOST();
          break;
        case "PUT":
          await this.requestPUT();
          break;
        case "DELETE":
          await this.requestDELETE();
          break;
      }
    },
    async requestGET(headers) {
      let params = new URLSearchParams(
        this.field.action.params ?? {}
      ).toString();
      await this.ActionApiService.get(
        this.field.action.url,
        "?" + params,
        headers
      )
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async requestPOST(headers) {
      await this.ActionApiService.post(
        this.field.action.url,
        this.field.action.params,
        headers
      )
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async requestPUT(headers) {
      await this.ActionApiService.put(
        this.field.action.url,
        this.field.action.params,
        headers
      )
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async requestDELETE(headers) {
      await this.ActionApiService.delete(this.field.action.url, headers)
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    noAction() {
      // Fire toast for information
      this.$toast.fire({
        icon: "error",
        title: this.$t("formHelper.noActionDefined")
      });
    },
    requestCanceled() {
      // Fire toast for information
      this.$toast.fire({
        icon: "info",
        title: this.$t("formHelper.requestCanceled")
      });
    }
  }
};
</script>
